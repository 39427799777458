import { configureStore } from '@reduxjs/toolkit';
import web3ProviderReducer from './web3/web3ProviderSlice';
import authReducer from "./auth/authSlice";
import messageReducer from './message/messageSlice';
import apesReducer from './apes/apesSlice';

export default configureStore({
    reducer: {
        web3Provider: web3ProviderReducer,
        auth: authReducer,
        message: messageReducer,
        apes: apesReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
