import * as React from "react";
import { Navigate } from "react-router-dom";
import {useSelector} from "react-redux";

import {selectAuthIsInitialized, selectAuthIsLoggedIn} from "../store/auth/authSlice";
import {selectAccounts} from "../store/web3/web3ProviderSlice";

interface GuestGuardType {
    children: React.ReactNode;
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
    const isAuthenticated = useSelector(selectAuthIsLoggedIn);
    const accounts = useSelector(selectAccounts);

    if (isAuthenticated) {
        return <Navigate to="/wallet" />;
    }

    return <React.Fragment>{children}</React.Fragment>;
}

export default GuestGuard;
