import styled from "styled-components";

export const NFTCardStyled = styled.div`
  position: relative;
  width: 317px;
  
  & > a {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: auto;
  }
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
`;

export const AssetNumber = styled.p`
  position: absolute;
  top: 28px;
  left: 23px;
  min-width: 125px;
  margin: 0;
  padding: 19px 27px 24px 27px;
  background-color: ${props => props.theme.colors.white};
  font-family: 'Alliance No.1';
  font-size: 24px;
  letter-spacing: -1.32px;
  border-radius: 36px;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    min-width: 88px;
    padding: 14px 19px 17px 19px;
    font-size: 17px;
    border-radius: 25px;
  }
`;
