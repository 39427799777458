import styled from "styled-components";

import {Text} from "../../../styles/general.style";

export const StepsListWrapper = styled.div`
  margin-top: 39px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
`;

export const StepsListStyled = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 62px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    margin-top: 60px;
  }
`;

export const StepItem = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const OverflowImage = styled.div`
  position: relative;
  height: 133px;
  width: 133px;
  
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    order: 1;
  }
`;

export const Image = styled.img`
  order: 1;
`;

export const Number = styled.h3`
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 44px;
  line-height: 1.409;
  color: ${props => props.theme.colors.white};
`;

export const TitleWrapper = styled.div`
  order: 0;
`;

export const Title = styled.h3`
  margin-top: 12px;
  margin-bottom: 0;
  color: ${props => props.theme.colors.white};
`;

export const Subtitle = styled(Text)`
  margin-top: 25px;
  margin-bottom: 0;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    order: 2;
  }
`;
