import { createSlice } from '@reduxjs/toolkit'

type initialStateType = {
    provider: any,
    accounts: string[]
}

export const web3ProviderSlice = createSlice({
    name: 'web3Provider',
    initialState: {
        provider: null,
        accounts: [],
        metamaskConnecting: false
    },
    reducers: {
        setProvider: (state, action) => {
            state.provider = action.payload.provider;
        },
        setAccounts: (state, action) => {
            state.accounts = action.payload.accounts;
        },
        handleMetamaskConnecting: (state, action) => {
            state.metamaskConnecting = action.payload.isConnecting;
        },
    },
})

export const { setProvider, setAccounts, handleMetamaskConnecting } = web3ProviderSlice.actions;

export const selectProvider = (state: any) => state.web3Provider.provider;
export const selectAccounts = (state: any) => state.web3Provider.accounts;
export const selectMetamaskConnecting = (state: any) => state.web3Provider.metamaskConnecting;


export default web3ProviderSlice.reducer;
