import styled from "styled-components";
import {motion} from "framer-motion";

import {TitleHeading} from "../../styles/general.style";

export const WalletAssets = styled.div`
  
`;

export const Title = styled(TitleHeading)`
  margin-top: 43px;
  margin-bottom: -86px;
  color: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 30px;
    margin-bottom: 23px;
  }
`;

export const OwnAssets = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const AssetsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: -5px;
  margin-right: -5px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const NoAssets = styled.div`
  width: 254px;
  align-self: center;
  font-size: 23px;
  color: ${props => props.theme.colors.white};
`;

export const AssetItem = styled(motion.div)`
  margin-bottom: 20px;
  
  &.our-asset {
    margin-top: 32px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      margin-top: 0;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 0;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AssetItemInner = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`;

export const OurAssets = styled.div`
  margin-bottom: -32px;
  padding: 24px 38px 32px 37px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 46px;
  
  h2 {
    margin-bottom: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 30px;
    margin-bottom: 0;
    padding: 17px 27px 23px 26px;
    
    h2 {
      margin-bottom: 22px;
    }
  }
`;
