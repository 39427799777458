import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import {selectAccounts} from "../../store/web3/web3ProviderSlice";

import {
    Address,
    LogoWrapper,
    WalletImage,
    WalletWrapper,
    WalletAddressStyled, GuestText
} from "./WalletAddress.style";
import {Logo} from "../../pages/home/Home.style";

import logo from "../../resources/img/logo.svg";
import walletImage from "../../resources/img/after-card.png";

const variantsLogo = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4
        }
    },
}

const variantsWalletAddress = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.2
        }
    },
}

const variantsWalletAddressSpan = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsWalletImg = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.4,
            delay: 0.2
        }
    },
}

const WalletAddress = () => {
    const accounts = useSelector(selectAccounts);

    return (
        <>
            <LogoWrapper
                initial="hidden"
                animate="visible"
                variants={variantsLogo}
            >
                <Link to="/">
                    <Logo src={logo} />
                </Link>
            </LogoWrapper>
            <WalletWrapper>
                <WalletAddressStyled
                    initial="hidden"
                    animate="visible"
                    variants={variantsWalletAddress}
                >Hello, <Address
                    initial="hidden"
                    animate="visible"
                    variants={variantsWalletAddressSpan}
                >
                    {accounts[0] ? accounts[0] : (
                        <>
                            <GuestText><span>0x0000000000000000000000000000000000000000</span></GuestText>
                        </>
                    )}
                </Address></WalletAddressStyled>
                <WalletImage
                    src={walletImage}
                    initial="hidden"
                    animate="visible"
                    variants={variantsWalletImg}
                />
            </WalletWrapper>
        </>
    );
};

export default WalletAddress;
