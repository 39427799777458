import styled, { css } from "styled-components";

const sizeStyles = (size = '') =>
    ({
        big: css`
          max-width: 1098px;
        `,
    }[size]);

export const AppStyled = styled.div`
  width: 100%;
  padding-bottom: 40px;
  overflow-x: hidden;
  background-color: ${props => props.theme.colors.black};
`;

export const Container = styled('div')<{ size?: string }>`
  max-width: 1048px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  ${({ size }) => sizeStyles(size)}
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: -0.54px;
`;

export const TitleHeading = styled.h2`
  font-family: 'Alliance No.1', sans-serif;
  font-size: 41px;
  letter-spacing: -2.25px;
  margin: 0;
  line-height: 1.512;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 29px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 25px;
  border-radius: 46px;
  padding: 35px;
  border: 1px solid rgba(0, 0, 0, .16);
  background-color: ${props => props.theme.colors.white};
  margin-top: 15px;
  
  > .content {
    flex: 1;
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 24px;

    > div:nth-child(2) {
      margin-top: 20px;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;
