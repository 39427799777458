import React, {useState} from 'react';
import {useSelector} from "react-redux";
import MetaMaskOnboarding from '@metamask/onboarding';

import {useModal} from "../../contexts/ModalContext";
import {connectMetamask} from "../../store/web3/metamask";
import {selectMetamaskConnecting} from "../../store/web3/web3ProviderSlice";

import {
    ButtonClose,
    ModalConnectWalletStyled,
    ModalTitle,
    Image,
    Name,
    WalletItem,
    WalletList,
    ModalWrapperInner
} from './ModalConnectWallet.style';

import iconClose from '../../resources/img/icon-close.png';
import logoMetamask from '../../resources/img/wallets/metamask.svg';
import logoWalletconnect from '../../resources/img/wallets/walletconnect.svg';

const ModalConnectWallet = () => {
    // @ts-ignore
    const {modalState, setModalState} = useModal();
    const metamaskConnecting = useSelector(selectMetamaskConnecting);
    const onboarding: any = React.useRef();
    const [showInstall, setShowInstall] = useState(false);

    React.useEffect(() => {
        if (!onboarding.current) {
            // @ts-ignore
            onboarding.current = new MetaMaskOnboarding();
        }
    }, []);

    const closeModal = () => {
        setModalState({isOpen: false})
    }

    const handleConnectMetamask = () => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            connectMetamask(closeModal);
        } else {
            if(showInstall) {
                onboarding?.current.startOnboarding();
            } else {
                setShowInstall(true)
            }
        }
    }

    return (
        <ModalConnectWalletStyled state={modalState.isOpen ? "show" : ""}>
            <ModalWrapperInner state={modalState.isOpen ? "show" : ""}>
                <ButtonClose
                    src={iconClose}
                    onClick={closeModal}
                />
                <ModalTitle>Connect your wallet</ModalTitle>
                <WalletList>
                    <WalletItem onClick={!metamaskConnecting ? handleConnectMetamask : () => false}>
                        <Image src={logoMetamask} />
                        <Name>Metamask</Name>
                        {metamaskConnecting ? "Connecting" : null}
                        {showInstall ? "Click here to install Metamask first!" : null}
                    </WalletItem>
                    {/*<WalletItem>*/}
                    {/*    <Image src={logoWalletconnect} />*/}
                    {/*    <Name>WalletConnect</Name>*/}
                    {/*</WalletItem>*/}
                </WalletList>
            </ModalWrapperInner>
        </ModalConnectWalletStyled>
    );
};

export default ModalConnectWallet;
