import {createContext, useContext, useState, Dispatch, SetStateAction, ReactNode,} from 'react';

type ModalType = {
    isOpen: boolean,
}

export type ModalContextType = {
    modalState: ModalType,
    setModalState: Dispatch<SetStateAction<ModalType>>
}

const LocalStateContext = createContext<ModalContextType | null>(null);
const LocalStateProvider = LocalStateContext.Provider;

type ModalProviderType = {
    children: ReactNode
}

const ModalProvider = ({children}: ModalProviderType) => {
    const [modalState, setModalState] = useState({isOpen: false});

    return <LocalStateProvider
        value={{
            modalState, setModalState
        }}
    >
        {children}
    </LocalStateProvider>;
}

const useModal = () => {
    return useContext(LocalStateContext);
}

export {ModalProvider, useModal};
