import React, {ReactNode} from 'react';

import { ButtonStyled } from './Button.style';

type ButtonProps = {
    children: ReactNode,
    link?: string,
    onClick?: () => void,
    variant?: "light",
    type?: "v2" | "v3"
};

const Button = (
    {
        children,
        link,
        onClick,
        variant,
        type
    }: ButtonProps
) => {

    const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if(onClick) {
            event.preventDefault();
            onClick();
        }
    }

    return (
        <ButtonStyled
            to={link ? link : ""}
            onClick={handleOnClick}
            variant={variant ? variant : ''}
            type={type ? type : ''}
        >
            {children}
        </ButtonStyled>
    );
};

export default Button;
