import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/messageSlice";
import ApesService from "./services/apes.service";
import {ApeType} from "../../types/apes";

export const getDefaultApes = createAsyncThunk(
    "apes/getDefaultApes",
    async ({none}: any, thunkAPI) => {
        try {
            const data = await ApesService.getDefaultApes();
            return { defaultApes: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

type generatePayloadType = {
    apedata: ApeType,
    text: string
}

export const generateApe = createAsyncThunk(
    "apes/generateApe",
    async ({apedata, text}: generatePayloadType, thunkAPI) => {
        try {
            const data = await ApesService.generateApe(apedata, text);
            return { generatedApe: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const checkGeneratedApe = createAsyncThunk(
    "apes/checkGeneratedApe",
    async ({url}: any, thunkAPI) => {
        try {
            const data = await ApesService.checkGeneratedApe(url);
            return { generatedApe: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const sendLinkToEmail = createAsyncThunk(
    "apes/sendLinkToEmail",
    async ({videoHash, email}: any, thunkAPI) => {
        try {
            const data = await ApesService.sendLinkToEmail(videoHash, email);
            return { sendLinkToEmail: data };
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    defaultApes: [],
    defaultApesIsLoading: false,
    generatedApe: null,
    generatedApeIsLoading: false,
    generateApeCallback: null
}

const apesSlice = createSlice({
    name: "apes",
    initialState,
    reducers: {
        resetGeneratedApes: (state) => {
            state.generatedApe = initialState.generatedApe;
            state.generateApeCallback = initialState.generateApeCallback;
        },
    },
    extraReducers: (builder => {
        builder
            .addCase(getDefaultApes.pending, (state, action) => {
                state.defaultApesIsLoading = true;
            })
            .addCase(getDefaultApes.fulfilled, (state, action) => {
                state.defaultApes = action.payload.defaultApes;
                state.defaultApesIsLoading = false;
            })
            .addCase(generateApe.pending, (state, action) => {
                state.generatedApeIsLoading = true;
            })
            .addCase(generateApe.fulfilled, (state, action) => {
                state.generateApeCallback = action.payload.generatedApe.callback;
                state.generatedApeIsLoading = false;
            })
            .addCase(generateApe.rejected, (state, action) => {
                state.generatedApeIsLoading = false;
            })
            .addCase(checkGeneratedApe.fulfilled, (state, action) => {
                state.generatedApe = action.payload.generatedApe;
                if(action.payload.generatedApe.output) {
                    state.generateApeCallback = initialState.generateApeCallback;
                }
            })
            .addCase(sendLinkToEmail.fulfilled, (state, action) => {

            })
    })
});

export const { resetGeneratedApes } = apesSlice.actions;

export const selectDefaultApes = (state: any) => state.apes.defaultApes;
export const selectDefaultApesIsLoading = (state: any) => state.apes.defaultApesIsLoading;
export const selectGeneratedApeIsLoading = (state: any) => state.apes.generatedApeIsLoading;
export const selectGeneratedApe = (state: any) => state.apes.generatedApe;
export const selectGeneratedApeCallback = (state: any) => state.apes.generateApeCallback;

const { reducer } = apesSlice;
export default reducer;
