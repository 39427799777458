import styled from "styled-components";
import {motion} from "framer-motion";

import {TitleHeading} from "../../styles/general.style";

export const LogoWrapper = styled(motion.div)`
  & img {
    margin-top: 90px;
    margin-bottom: 35px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    & img {
      margin-top: 30px;
    }
  }
`;

export const WalletWrapper = styled.div`
  display: inline-block;
  max-width: 100%;
  position: relative;
  padding-right: 150px;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-right: 0px;
  }
`;

export const WalletImage = styled(motion.img)`
  position: absolute;
  top: -329px;
  right: -177px;
  pointer-events: none;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 413px;
    top: -225px;
    right: -147px;
  }
`;

export const WalletAddressStyled = styled(motion(TitleHeading))`
  max-width: 637px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 22px 70px 33px 37px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 26px;
  overflow-x: auto;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 15px 70px 23px 26px;
  }
`;

export const Address = styled(motion.span)`
  margin-left: 11px;
  font-family: 'Alliance No.2';
  font-size: 20px;
  color: rgba(0, 0, 0, 0.46);
  letter-spacing: -1.1px;
`;

export const GuestText = styled.span`
  position: relative;
  
  &:before {
    content: "Guest";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  & > span {
    opacity: 0;
  }
`;
