import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://apes-api.humans.ai/v1/user/";

// const login = (token: string) => {
//     return axios
//         .post(API_URL, {token})
//         .then((response: any) => {
//             console.log(response)
//             if (response.data.token) {
//                 localStorage.setItem("accessToken", JSON.stringify(response.data.token));
//             }
//             return response.data.token;
//         });
// };

const getNonce = (publicaddress: string) => {
    return axios
        .post(API_URL, {publicaddress})
        .then((response: any) => {
            return response.data.nonce;
        });
};

const sendSignedNonceMsg = (publicaddress: string, signature: string) => {
    return axios
        .post(API_URL, {publicaddress, signature})
        .then((response: any) => {
            return response.data.token;
        });
}

const logout = () => {
    localStorage.removeItem("accessToken");
};

const getUser = () => {
    return axios
        .get(API_URL, {
            // @ts-ignore
            headers: authHeader()
        })
        .then((response: any) => {
            return response.data;
        });
};

const authService = {
    getNonce,
    sendSignedNonceMsg,
    logout,
    getUser,
};
export default authService;
