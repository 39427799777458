import styled from "styled-components";
import { TitleHeading } from '../../styles/general.style';

export const Gallery = styled.div`
  width: 31.48%;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: auto;
  }
`;

export const FormWrapper = styled.form`
  flex: 1;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 20px;
  }
`;

export const Title = styled(TitleHeading)`
  margin-bottom: 30px;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DropdownWrapper = styled.div`
  border-radius: 46px;
  border: 1px solid rgba(0, 0, 0, .16);
  box-shadow: 0 2px 18px 2px rgba(0,0,0,0.11);
  background-color: ${props => props.theme.colors.white};
  position: relative;
  min-width: auto;
  transition: all .1s ease-in-out;
  
  &.open {
    width: 425px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    
    .arrow-down {
      transform: rotate(180deg);
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;

export const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 32px 11px 14px;
`;

export const DropdownLogo = styled.img`
  margin-right: 8px;
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 38px;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, .16);
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 4px 2px rgba(0,0,0,0.11);
  cursor: pointer;
  border-radius: 50%;
  padding: 0;
  margin-right: 10px;
`;

export const NameVoice = styled.p`
  font-family: 'Alliance No.1', sans-serif;
  font-size: 19px;
  letter-spacing: -0.54px;
  cursor: pointer;
`;

export const ArrowDown = styled.img`
  cursor: pointer;
  margin-left: 34px;
  transition: all .2s ease-in-out;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 425px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;

export const LogoImage = styled.img`
  margin-left: 33px;
  opacity: .25;
  
  &.open {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 0;
    margin-top: 15px;

    &.open {
      display: block;
    }
  }
`;

export const Textarea = styled.textarea`
  font-family: 'Alliance No.2', sans-serif;
  font-size: 20px;
  letter-spacing: -0.54px;
  resize: none;
  width: 100%;
  height: 200px;
  border-radius: 26px;
  padding: 35px;
  border: 1px solid rgba(0, 0, 0, .16);
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 18px 2px rgba(0,0,0,0.11);
  box-sizing: border-box;
  
  &:focus {
    outline: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 24px;
  }
`;

export const SubmitWrapper = styled.div`
  margin-top: 16px;
`;

export const ButtonForm = styled.button`
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  cursor: pointer;
  
  & > * {
    pointer-events: none;
  }
`;
