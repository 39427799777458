import styled from "styled-components";
import { motion } from "framer-motion";

import {Text} from "../../styles/general.style";

import beforeCard from '../../resources/img/before-card.png';
import afterCard from '../../resources/img/after-card.png';
import beforeTitle from '../../resources/img/bg-title.svg';

export const Logo = styled(motion.img)`
  margin-top: 104px;
  margin-bottom: 84px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 110px;
    margin-top: 30px;
    margin-bottom: 60px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Description = styled.div`
  z-index: 2;
`;

export const Title = styled(motion.h1)`
  display: inline-block;
  position: relative;
  margin-top: 95px;
  margin-bottom: 0;
  font-size: 69px;
  line-height: 0.899;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
  letter-spacing: -3.79px;
  
  &:before {
    content: "";
    display: block;
    width: 209px;
    height: 163.87px;
    position: absolute;
    top: -34px;
    right: -85px;
    background-image: url(${beforeTitle});
    background-size: 100%;
  }

  &:after {
    content: "";
    display: block;
    width: 109px;
    height: 6px;
    background-color: ${props => props.theme.colors.blue};
    position: absolute;
    right: 82px;
    bottom: -8px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 43px;
    
    &:before {
      width: 146.29px;
      height: 114.7px;
      top: -26px;
      right: -81px;
    }
    
    &:after {
      width: 76px;
      right: 49px;
      height: 4px;
      bottom: -6px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 0;
  }
`;

export const Subtitle = styled(motion(Text))`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const CardHeader = styled(motion.div)`
  position: relative;
  z-index: 1;
  
  &:before {
    content: "";
    display: block;
    height: 406px;
    width: 406px;
    background-image: url(${beforeCard});
    background-size: 100%;
    position: absolute;
    left: -63%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }

  &:after {
    content: "";
    display: block;
    height: 605px;
    width: 590px;
    background-image: url(${afterCard});
    background-size: 100%;
    position: absolute;
    left: 32px;
    top: -271px;
    z-index: 1;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: inline-block;
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    &:before {
      height: 284.2px;
      width: 284.2px;
      left: -39%;
    }
    
    &:after {
      height: 423.5px;
      width: 413px;
      left: 87px;
      top: -173px;
    }
    
    & img {
      max-width: 90%;
    }
  }
`;

export const Banner = styled(motion.div)`
  margin-top: 69px;
  display: flex;
  justify-content: space-between;
  max-width: 706px;
  background-color: ${props => props.theme.colors.banner};
  border-radius: 45px;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
  }
`;

export const BannerContent = styled.div`
  padding: 65px 51px 45px 51px;
`;

export const BannerTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.125;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
  letter-spacing: -1.76px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 23px;
  }
`;

export const BannerImage = styled.img`
  margin-right: 60px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 0;
  }
`;
