import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const variantStyles = (variant = '') =>
    ({
        light: css`
          background: ${props => props.theme.colors.white};
          color: ${props => props.theme.colors.black};
        `,
    }[variant]);

const typeStyles = (type = '') =>
    ({
        v2: css`
          border-radius: 27px;
          font-weight: 600;
        `,

        v3: css`
          padding-left: 29px;
          padding-right: 29px;
          border-radius: 27px;
          
          p {
            display: flex;
            justify-content: space-between;
            column-gap: 20px;
            margin: 0;
            font-family: 'Avenir LT Std';
            
            span {
              color: rgba(255, 255, 255, 0.37);
            }
          }
        `,
    }[type]);

export const ButtonStyled = styled(Link)<{ variant: string, type: string }>`
  display: inline-block;
  min-width: 211px;
  padding: 18px 21.5px;
  background-color: ${props => props.theme.colors.blue};
  font-family: 'Avenir LT Std';
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  
  ${({ variant }) => variantStyles(variant)}
  ${({ type }) => typeStyles(type)}
`;
