import React from 'react';

import Button from "../button/Button";

import {
    AssetNumber,
    Image,
    NFTCardStyled
} from "./NFTCard.style";

type NFTCardTypeProps = {
    hasButton?: boolean,
    buttonText?: string,
    buttonLink?: string,
    image: string,
    cardNumber?: string
};

const NFTCard = (
    {
        hasButton,
        buttonText,
        buttonLink,
        image,
        cardNumber
}: NFTCardTypeProps) => {
    return (
        <NFTCardStyled>
            <Image src={image} />
            {cardNumber && <AssetNumber>{cardNumber}</AssetNumber>}

            {hasButton && buttonText &&
			  <Button
				children={buttonText || ''}
				link={buttonLink}
                variant="light"
                type="v2"
			  />
            }
        </NFTCardStyled>
    );
};

export default NFTCard;
