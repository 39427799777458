import React from 'react';
import { Routes, Route } from "react-router-dom";

import AuthGuard from "./guards/AuthGuard";
import GuestGuard from "./guards/GuestGuard";

import Home from "./pages/home/Home";
import Wallet from "./pages/wallet/Wallet";
import Generate from "./pages/generate/Generate";
import Share from "./pages/share/Share";

const RoutesList = () => {
    return (
        <Routes>
            <Route path="/" element={<GuestGuard><Home /></GuestGuard>} />
            <Route path="/wallet" element={<AuthGuard><Wallet /></AuthGuard>} />
            <Route path="/generate/:id" element={<AuthGuard><Generate /></AuthGuard>} />
            <Route path="/share/:taskId" element={<Share />} />
        </Routes>
    );
};

export default RoutesList;
