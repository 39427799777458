import styled, { css } from "styled-components";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

import { TitleHeading } from '../../styles/general.style';

const buttonStyles = (type = '') =>
    ({
        inverse: css`
          background-color: ${props => props.theme.colors.white};
          border-color: ${props => props.theme.colors.black};
          color: ${props => props.theme.colors.black};
        `,
    }[type]);


export const CardOuter = styled(motion.div)`
  position: relative;
`;

export const StausBg = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: white;
  z-index: 2;
  border-radius: 46px;
`;

export const Status = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 2;
  border-radius: 46px;
`;

export const StatusContent = styled.div`
  width: 100%;
  max-width: 600px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormEmail = styled.form`
  text-align: center;
  
  p {
    display: block;
  }
  
  input {
    min-width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 38px;
    font-family: 'Avenir Next LT Pro';
    border: 1px solid ${props => props.theme.colors.black};
    border-right: 0;
    border-radius: 12px 0 0 12px;
    
    &:focus {
      outline: 0;
    }
  }
  
  button {
    border-radius: 0 12px 12px 0;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    font-family: 'Avenir LT Std';
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    line-height: 40px;
    background-color: ${props => props.theme.colors.blue};
    cursor: pointer;
    
    &:disabled {
      background-color: gray;
    }
  }
`;

export const VideoWrapper = styled(motion.div)`
  position: relative;
  
  video {
    max-width: 100%;
  }
`;

export const PlayWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const PlayBtnWrapper = styled.div`
  position: absolute;
  top: 44px;
  left: 39px;
  width: 78px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;
`;

export const PlayBtn = styled.img`
  display: block;
  width: 27px!important;
`;

export const Gallery = styled.div`
  width: 41.5%;
  
  img {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;

    img {
      display: block;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Title = styled(TitleHeading)`
  margin-bottom: 15px;
`;

const ShareButtonBase = css<{ type?: string }>`
  position: relative;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 88px;
  padding: 24px 26px;
  padding-left: 10px;
  border-radius: 26px;
  border: 4px solid transparent;
  background-color: ${props => props.theme.colors.black};
  font-family: 'Alliance No.1', sans-serif;
  font-size: 29px;
  letter-spacing: -.54px;
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  z-index: 1;
  
  ${({ type }) => buttonStyles(type)}
  
  &:last-child {
    margin-bottom: 0;
  }
  
  button {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;
  }
  
  img {
    width: 48px;
    height: 48px;
    margin-right: 20px;
    margin-left: 14px;
  }

  svg {
    margin-right: 5px;
    fill: ${props => props.theme.colors.white};
  }
  
  a {
    text-decoration: none;
    margin: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 20px;
    height: 62px;
    padding: 17px 18px;
    border-radius: 18px;
    border-width: 3px;

    img, svg {
      width: 34px;
      height: 34px;
      margin-right: 15px;
    }
  }
`;

export const ShareButton = styled(motion.div)<{ type?: string }>`
  ${ShareButtonBase};
`;

export const ShareButtonLink = styled(motion(Link))`
  ${ShareButtonBase};
  text-decoration: none;
`;
