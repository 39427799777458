export const theme = {
    colors: {
        white: '#ffffff',
        black: '#000000',
        blue: '#1534EF',
        banner: '#735C71'
    },
    breakpoints: {
        sm: "767px",
        md: "992px"
    }
};
