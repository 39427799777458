import React from 'react';

import Button from "../../../components/button/Button";
import {
    StepItem,
    StepsListStyled,
    StepsListWrapper,
    Image,
    Number,
    Title,
    Subtitle,
    TitleWrapper,
    OverflowImage
} from './StepsList.style';

import stepImage1 from '../../../resources/img/steps/step-1.png';
import stepImage2 from '../../../resources/img/steps/step-2.png';
import stepImage3 from '../../../resources/img/steps/step-3.png';

type StepsListType = {
    handleConnect: () => void,
    connectButtonText: () => string
}

const StepsList = ({ handleConnect, connectButtonText }: StepsListType) => {
    return (
        <StepsListWrapper>
            <StepsListStyled>
                <StepItem>
                    <Image src={stepImage1} />
                    <TitleWrapper>
                        <Number>1</Number>
                        <Title>Prove ownership</Title>
                    </TitleWrapper>
                    <Subtitle>
                        Mixing AI technology with NTFs so you can make any NFT speak your story, your ideas. To be the voice of a generation.
                    </Subtitle>
                </StepItem>
                <StepItem>
                    <Image src={stepImage2} />
                    <TitleWrapper>
                        <Number>2</Number>
                        <Title>Select your ape</Title>
                    </TitleWrapper>
                    <Subtitle>
                        Mixing AI technology with NTFs so you can make any NFT speak your story, your ideas. To be the voice of a generation.
                    </Subtitle>
                </StepItem>
                <StepItem>
                    <OverflowImage>
                        <Image src={stepImage3} />
                    </OverflowImage>
                    <TitleWrapper>
                        <Number>3</Number>
                        <Title>Make it talk</Title>
                    </TitleWrapper>
                    <Subtitle>
                        Mixing AI technology with NTFs so you can make any NFT speak your story, your ideas. To be the voice of a generation.
                    </Subtitle>
                </StepItem>
            </StepsListStyled>
            <Button
                onClick={handleConnect}
            >{connectButtonText()}</Button>
        </StepsListWrapper>
    );
};

export default StepsList;
