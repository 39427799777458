import store from "../store";
import {handleMetamaskConnecting, setAccounts, setProvider} from "./web3ProviderSlice";
import Web3 from "web3";

import { MetaMaskInpageProvider } from "@metamask/providers";
import {getNonce, logoutUser, sendSignedNonceMsg} from "../auth/authSlice";
import {setMessage} from "../message/messageSlice";

declare global {
    interface Window {
        ethereum: MetaMaskInpageProvider;
    }
}

export const connectMetamask = (callback: () => void) => {
    // Modern dapp browsers...
    if (window.ethereum) {
        store.dispatch(handleMetamaskConnecting({isConnecting: true}));
        // @ts-ignore
        const provider = new Web3(window.ethereum);
        store.dispatch(setProvider({provider: provider}));
        window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then((accounts: any) => {
                handleChangeAccounts(accounts);
                changeChain();


                if(accounts[0]) {
                    // Get nonce
                    store.dispatch(getNonce({ publicaddress: accounts[0] }))
                        .unwrap()
                        .then(async (res) => {
                            console.log({res: res.nonce})
                            if (res.nonce) {

                                // Sign message
                                handleSignMessage({
                                    provider: provider,
                                    publicAddress: accounts[0],
                                    nonce: res.nonce
                                })
                                    .then((signedMessage: any) => {

                                        // Send signed message to server and expect to receive token
                                        store.dispatch(sendSignedNonceMsg({
                                            publicaddress: signedMessage.publicAddress,
                                            signature: signedMessage.signature
                                        })).unwrap()
                                            .then(resToken => {
                                                console.log({resToken})

                                                if(resToken.token) {
                                                    localStorage.setItem("accessToken", JSON.stringify(resToken.token));

                                                    if(callback) {
                                                        callback();
                                                    }
                                                }
                                            });
                                    })
                                    .catch(error => {
                                        console.log({error})
                                       store.dispatch(setMessage(error.message))
                                    })
                                    .finally(() => {
                                        store.dispatch(handleMetamaskConnecting({isConnecting: false}));
                                    });
                            }
                        });
                }
            })
            .catch((error: any) => {
                if (error.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    store.dispatch(setMessage("Please connect to MetaMask."));
                    store.dispatch(handleMetamaskConnecting({isConnecting: false}));
                } else {
                    // User denied account access...
                    store.dispatch(setMessage("User denied account access"));
                }
            });

        window.ethereum.on('accountsChanged', handleChangeAccounts);
    }
}


const handleSignMessage = ({provider, publicAddress, nonce }: {provider: any, publicAddress: string, nonce: string}) => {
    console.log(provider.eth)
    return new Promise((resolve, reject) =>
        provider.eth.personal.sign(
            Web3.utils.fromUtf8(`Humans.ai one time nonce signature ${nonce}`),
            publicAddress,
            (err: any, signature: any) => {
                if (err) return reject(err);
                return resolve({ publicAddress, signature });
            }
        )
    );
};

export const checkConnection = () => {
    if(window.ethereum) {
        window.ethereum
            .request({ method: 'eth_accounts' })
            .then((accounts: any) => {
                handleChangeAccounts(accounts);
                changeChain();
            })
            .catch(console.error);
        window.ethereum.on('accountsChanged', handleChangeAccounts);
    }
};

const handleChangeAccounts = (accounts: any) => {
    if(accounts.length === 0) {
        store.dispatch(logoutUser());
        store.dispatch(handleMetamaskConnecting({isConnecting: false}));
    }

    if(accounts.length > 0 && !store.getState().auth.accessToken) {

    }

    store.dispatch(setAccounts({accounts: accounts}));
};

const changeChain = async () => {
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
        });
    } catch (error: any) {
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
                });
            } catch (addError) {
                console.error(addError);
            }
        }
        console.error(error);
    }
}
