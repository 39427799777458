import 'react-app-polyfill/stable';
import React, {useEffect} from 'react';
import "normalize.css/normalize.css";
import "./styles/reset.css";
import "./resources/fonts/fonts.css";
import {ThemeProvider} from "styled-components";
import {theme} from "./styles/theme";
import { BrowserRouter } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import toast, { Toaster } from 'react-hot-toast';

import RoutesList from "./routes";
import {ModalProvider} from "./contexts/ModalContext";
import ModalConnectWallet from "./components/modal-connect-wallet/ModalConnectWallet";
import {checkConnection} from "./store/web3/metamask";

import {clearMessage, selectMessage} from "./store/message/messageSlice";
import {getUser, selectAccessToken, selectAuthIsLoggedIn} from "./store/auth/authSlice";

import {
    AppStyled,
} from "./styles/general.style";

function App() {
    const dispatch = useDispatch();
    const message = useSelector(selectMessage);
    const isLoggedIn = useSelector(selectAuthIsLoggedIn);
    const accessToken = useSelector(selectAccessToken);

    useEffect(() => {
        checkConnection();
    }, []);

    useEffect(() => {
        if(isLoggedIn) {
            dispatch(getUser({}));
        }
    }, [accessToken]);

    useEffect(() => {
        if(message) {
            toast(message, {
                position: "top-right"
            });
            dispatch(clearMessage());
        }
    }, [message])

    return (
        <ThemeProvider theme={theme}>
          <ModalProvider>
              <AppStyled>
                  <BrowserRouter>
                      <RoutesList />
                  </BrowserRouter>
                  <ModalConnectWallet />
                  <Toaster />
              </AppStyled>
          </ModalProvider>
        </ThemeProvider>
    );
}

export default App;
