export default function authHeader() {
    const accessTokenRaw = localStorage.getItem('accessToken');
    const accessToken = accessTokenRaw ? JSON.parse(accessTokenRaw) : null;

    if (accessToken) {
        return { Authorization: 'Bearer ' + accessToken };
    } else {
        return {};
    }
}
