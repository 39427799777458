import * as React from "react";
import { Navigate } from "react-router-dom";
import {useSelector} from "react-redux";

import {selectAuthIsInitialized, selectAuthIsLoggedIn} from "../store/auth/authSlice";
import {selectAccounts} from "../store/web3/web3ProviderSlice";

interface AuthGuardType {
    children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
    const isAuthenticated = useSelector(selectAuthIsLoggedIn);
    const accounts = useSelector(selectAccounts);

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
