import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { motion } from "framer-motion";

import {useModal} from "../../contexts/ModalContext";
import Button from "../../components/button/Button";
import StepsList from "./subcomponents/StepsList";

import {
    selectAccounts,
} from "../../store/web3/web3ProviderSlice";
import {selectAuthIsLoading, selectAuthIsLoggedIn} from "../../store/auth/authSlice";

import {Container} from "../../styles/general.style";
import {
    Banner,
    BannerContent,
    BannerImage,
    BannerTitle,
    CardHeader,
    ContentWrapper,
    Description,
    Logo,
    Subtitle,
    Title
} from "./Home.style";

import logo from '../../resources/img/logo.svg';
import cardHeader from "../../resources/img/cards/card-1.png";
import banner from "../../resources/img/banner.png";

const variantsLogo = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4
        }
    },
}

const variantsTitle = {
    hidden: {
        opacity: 0,
        y: 80
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4
        }
    },
}

const variantsSubtitle = {
    hidden: {
        opacity: 0,
        y: -60
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4
        }
    },
}

const variantsButtonHeader = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.3,
            delay: 0.4
        }
    },
}

const variantsCardHeader = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.4,
        }
    },
}

const variantsSteps = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const Home = () => {
    const navigate = useNavigate();
    // @ts-ignore
    const {setModalState} = useModal();
    const accounts = useSelector(selectAccounts);
    const authIsLoading = useSelector(selectAuthIsLoading);
    const isLoggedIn = useSelector(selectAuthIsLoggedIn);

    useEffect(() => {
        // setModalState({isOpen: false});
    }, [accounts]);

    const handleConnect = () => {
        if(!isLoggedIn) {
            setModalState({isOpen: true});
        } else {
            navigate("/wallet");
        }
    }

    const connectButtonText = () => {
        if(!isLoggedIn) {
            return "Connect your wallet";
        } else if(authIsLoading) {
            return "Connecting"
        } else {
            return "Select Apes"
        }
    }

    return (
        <header>
            <Container>
                <Logo
                    src={logo}
                    initial="hidden"
                    animate="visible"
                    variants={variantsLogo}
                />
                <ContentWrapper>
                    <Description>
                        <Title
                            initial="hidden"
                            animate="visible"
                            variants={variantsTitle}
                        >Make your ape talk</Title>
                        <Subtitle
                            initial="hidden"
                            animate="visible"
                            variants={variantsSubtitle}
                        >Make it talk!</Subtitle>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={variantsButtonHeader}
                        >
                            <Button
                                onClick={handleConnect}
                            >{connectButtonText()}</Button>
                        </motion.div>
                    </Description>
                    <CardHeader
                        initial="hidden"
                        animate="visible"
                        variants={variantsCardHeader}
                    >
                        <img src={cardHeader} alt=""/>
                    </CardHeader>
                </ContentWrapper>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsSteps}
                >
                    <StepsList
                        handleConnect={handleConnect}
                        connectButtonText={connectButtonText}
                    />
                </motion.div>
                <Banner
                    initial="hidden"
                    animate="visible"
                    variants={variantsSteps}
                >
                    <BannerContent>
                        <BannerTitle>Don’t have an Ape? <br/> Use ours.</BannerTitle>
                        <Button
                            onClick={handleConnect}
                            variant="light"
                        >{connectButtonText()}</Button>
                    </BannerContent>
                    <BannerImage src={banner} />
                </Banner>
            </Container>
        </header>
    );
};

export default Home;
