import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {motion} from "framer-motion";

import WalletAddress from '../../components/wallet-address/WalletAddress';
import NFTCard from "../../components/nft-card/NFTCard";

import {
    getDefaultApes,
    selectDefaultApes, selectDefaultApesIsLoading,
} from "../../store/apes/apesSlice";
import {ApeType} from "../../types/apes";

import {
    Container,
    TitleHeading
} from "../../styles/general.style";

import {
    AssetsList,
    Title,
    WalletAssets,
    AssetItem,
    OwnAssets,
    OurAssets,
    AssetItemInner,
    NoAssets,
} from './Wallet.style';

import card1 from "../../resources/img/cards/card-1.png";
import card3 from "../../resources/img/cards/card-3.png";
import {selectUser} from "../../store/auth/authSlice";
import {UserType} from "../../types/user";

const variantsAssets = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const variantsAssetItem = {
    hidden: {
        opacity: 0,
        y: 50
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const Wallet = () => {
    const dispatch = useDispatch();
    const defaultApes: ApeType[] | [] = useSelector(selectDefaultApes);
    const defaultApesIsLoading = useSelector(selectDefaultApesIsLoading);
    const user: UserType | null = useSelector(selectUser);

    useEffect(() => {
        dispatch(getDefaultApes({none: ''}));
    }, [])

    const renderDefaultApes = () => {
        if(defaultApes.length > 0) {
            return defaultApes.map((ape) => (
                <AssetItem
                    className="our-asset" key={ape.id}
                    initial="hidden"
                    animate="visible"
                    variants={variantsAssetItem}
                >
                    <AssetItemInner>
                        <OurAssets>
                            <TitleHeading>Use ours</TitleHeading>
                            <NFTCard
                                hasButton={true}
                                buttonText="Make it talk"
                                buttonLink={`/generate/${ape.id}`}
                                image={ape.image}
                                cardNumber={`#${ape.id}`}
                            />
                        </OurAssets>
                    </AssetItemInner>
                </AssetItem>
            ));
        }
    }

    const renderOwnedApes = () => {
        if(user && user.nfts?.bayc.length > 0) {
            return user.nfts.bayc.map((ape) => (
                <AssetItem
                    className="own-asset" key={ape.id}
                    initial="hidden"
                    animate="visible"
                    variants={variantsAssetItem}
                >
                    <AssetItemInner>
                        <NFTCard
                            hasButton={true}
                            buttonText="Make it talk"
                            buttonLink={`/generate/${ape.id}`}
                            image={ape.image}
                            cardNumber={`#${ape.id}`}
                        />
                    </AssetItemInner>
                </AssetItem>
            ));
        } else return (
            <NoAssets>
                <p>You have no owned apes</p>
            </NoAssets>
        );
    }

    return (
        <Container>
            <WalletAssets>
                <WalletAddress />

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variantsAssets}
                >
                    {defaultApesIsLoading ?
                        <h2 style={{color: '#fff'}}>Loading your apes</h2>
                        :
                        <>
                            <Title>Your wallet</Title>
                            <AssetsList>
                                {/*<AssetItem className="own-asset">*/}
                                {/*    <AssetItemInner>*/}
                                {/*        <NFTCard*/}
                                {/*            hasButton={true}*/}
                                {/*            buttonText="Make it talk"*/}
                                {/*            buttonLink="/generate"*/}
                                {/*            image={card1}*/}
                                {/*            cardNumber="#3303"*/}
                                {/*        />*/}
                                {/*    </AssetItemInner>*/}
                                {/*</AssetItem>*/}
                                {/*<AssetItem className="own-asset">*/}
                                {/*    <AssetItemInner>*/}
                                {/*        <NFTCard*/}
                                {/*            hasButton={true}*/}
                                {/*            buttonText="Make it talk"*/}
                                {/*            buttonLink="/generate"*/}
                                {/*            image={card1}*/}
                                {/*            cardNumber="#3303"*/}
                                {/*        />*/}
                                {/*    </AssetItemInner>*/}
                                {/*</AssetItem>*/}

                                {renderOwnedApes()}
                                {renderDefaultApes()}
                            </AssetsList>
                        </>
                    }
                </motion.div>
            </WalletAssets>
        </Container>
    );
};

export default Wallet;
