import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { motion } from 'framer-motion';

import Button from "../../components/button/Button";
import WalletAddress from '../../components/wallet-address/WalletAddress';
import NFTCard from "../../components/nft-card/NFTCard";

import {
    generateApe,
    selectDefaultApes,
    selectGeneratedApeCallback,
    selectGeneratedApeIsLoading
} from "../../store/apes/apesSlice";
import {ApeType} from "../../types/apes";

import { Container, CardWrapper } from '../../styles/general.style';
import {
    FormWrapper,
    Gallery,
    LogoImage,
    Title,
    SelectContainer,
    DropdownWrapper,
    Textarea,
    SubmitWrapper,
    DropdownButton,
    DropdownLogo,
    PlayButton,
    ArrowDown, NameVoice, DropdownContent, ButtonForm,
} from './Generate.style';

import logo from "../../resources/img/logo-humans-voices.svg";
import logoImg from "../../resources/img/logo-img.svg";
import triangle from "../../resources/img/triangle.svg";
import arrowDown from "../../resources/img/arrow-down.svg";
import banner from "../../resources/img/banner-voice.png";
import {UserType} from "../../types/user";
import {selectUser} from "../../store/auth/authSlice";

const variantsGenerate = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4
        }
    },
}

const Generate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [text, setText] = useState('');
    const user: UserType | null = useSelector(selectUser);

    const generatedApeIsLoading = useSelector(selectGeneratedApeIsLoading);
    const generatedApeCallback = useSelector(selectGeneratedApeCallback);
    const defaultApes: ApeType[] | [] = useSelector(selectDefaultApes);
    const { id } = useParams();
    const defaultApe = defaultApes.find(ape => ape.id === Number(id));
    const ownedApe = user?.nfts?.bayc.find(ape => ape.id === Number(id));
    const selectedApe = ownedApe || defaultApe;

    const getTaskId = (url: string) => {
        const idKey = "task=";
        return url.substring(generatedApeCallback.indexOf(idKey) + idKey.length);
    }

    useEffect(() => {
        if(generatedApeCallback) {
            navigate(`/share/${getTaskId(generatedApeCallback)}`)
        }
    }, [generatedApeCallback, navigate])

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const handleGenerate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(generatedApeIsLoading) return;

        if(selectedApe && text) {
            dispatch(generateApe({apedata: selectedApe, text}));
        }
    }

    return (
        <Container size="big">
            <WalletAddress/>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={variantsGenerate}
            >
                <CardWrapper>
                    <Gallery>
                        {selectedApe && (
                            <NFTCard
                                image={selectedApe.image}
                                cardNumber={`#${selectedApe.id}`}
                            />
                        )}
                    </Gallery>
                    <FormWrapper onSubmit={handleGenerate}>
                        <Title>Make it talk</Title>
                        <SelectContainer>
                            <DropdownWrapper className={dropdownOpen ? 'open' : ''}>
                                <DropdownButton>
                                    <DropdownLogo src={logoImg} alt=""/>
                                    <PlayButton onClick={(e) => {
                                        e.preventDefault();
                                        console.log('play');
                                    }}>
                                        <img src={triangle} alt=""/>
                                    </PlayButton>
                                    <NameVoice onClick={toggleDropdown}>Voice
                                        #001</NameVoice>
                                    <ArrowDown src={arrowDown} onClick={toggleDropdown} className="arrow-down" />
                                </DropdownButton>
                                {
                                    dropdownOpen ?
                                        <DropdownContent>
                                            <img src={banner} alt=""/>
                                        </DropdownContent> : null
                                }
                            </DropdownWrapper>
                            <LogoImage src={logo} className={dropdownOpen ? 'open' : ''}/>
                        </SelectContainer>
                        <Textarea
                            name="your-text"
                            id="your-text"
                            placeholder="Write your text"
                            value={text}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)}
                            required={true}
                        />
                        <SubmitWrapper>
                            <ButtonForm type={"submit"}>
                                <Button
                                    type="v3"
                                >
                                    {generatedApeIsLoading ?
                                        <p>Generating</p>
                                        :
                                        <p>Make it talk <span>200 $HEART</span></p>
                                    }

                                </Button>
                            </ButtonForm>
                        </SubmitWrapper>
                    </FormWrapper>
                </CardWrapper>
            </motion.div>
        </Container>
    );
};

export default Generate;
